import appConfig from "../config/app.config";
import axios from "axios";
import { getUserToken } from "../libs/user";
import {
  fireReloginPopup,
  getPureJSON,
  handleAPIError,
  handleError,
  startLoader,
  stopLoader,
} from "../libs/utils";

const bankAddRuleList = () => {
  startLoader();
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: appConfig.apiUrl.bankAddRule,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        handleError({ error, reject });
      });
  });
};
const getBankAddRuleById = (id) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.bankAddRule}/${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        if (error?.response?.status === 401) {
          fireReloginPopup();
          return;
        }
        handleAPIError(error.status);
        reject(getPureJSON(error.response || error));
      });
  });
};

const editBankAddRule = (id, obj) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const data = JSON.stringify(Object.assign({ id: id }, obj));

    const config = {
      method: "post",
      url: `${appConfig.apiUrl.bankAddRule}/save`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        handleError({ error, reject });
      });
  });
};

const addBankAddRule = (obj) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const data = JSON.stringify(Object.assign({ id: null }, obj));

    const config = {
      method: "post",
      url: `${appConfig.apiUrl.bankAddRule}/save`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        handleError({ error, reject });
      });
  });
};

export { bankAddRuleList, getBankAddRuleById, editBankAddRule, addBankAddRule };
