import appConfig from "../config/app.config";
import axios from "axios";
import { getUserToken } from "../libs/user";
import {
  fireReloginPopup,
  getPureJSON,
  handleAPIError,
  handleError,
  startLoader,
  stopLoader,
} from "../libs/utils";

const getmerchantMappingList = (obj) => {
  startLoader();
  return new Promise((resolve, reject) => {
    const data = JSON.stringify({
      offset: obj.offset,
      size: obj.size,
      startTime: obj.startTime,
      endTime: obj.endTime,
      transactionStatus: obj.transactionStatus || "MANUALLY",
    });
    const config = {
      method: "post",
      url: appConfig.apiUrl.getmerchantMappingAuditList,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        Accept: " application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        if (error?.response?.status === 401) {
          fireReloginPopup();
          return;
        }
        reject(error);
      });
  });
};

const getOfferStoreDataById = (id) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.getstoresallList}/${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        if (error?.response?.status === 401) {
          fireReloginPopup();
          return;
        }
        handleAPIError(error.status);
        reject(getPureJSON(error.response || error));
      });
  });
};
const gettransactionDataById = (id) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.getMerchantslists}/transaction/${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        handleError({ error, reject });
      });
  });
};

const getMerchantUser = (email) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.getMerchantsusers}/${email}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        handleError({ error, reject });
      });
  });
};
const getOfferDatas = (id) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.getofferData}/${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        handleError({ error, reject });
      });
  });
};

const updateOfferStoresRecord = (obj) => {
  const authToken = getUserToken();
  return new Promise((resolve, reject) => {
    var config = {
      method: "post",
      url: appConfig.apiUrl.updateofferstoresRecord,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        handleError({ error, reject });
      });
  });
};

const getTransactionsList = (obj) => {
  return new Promise((resolve, reject) => {
    var data = JSON.stringify({
      offset: obj.offset,
      size: obj.size,
      filter: {
        email: obj.email,
        name: obj.name,
        processed: obj.processed,
        status: obj.status,
      },
      fromDate: obj.fromDate,
      toDate: obj.toDate,
    });
    var config = {
      method: "post",
      url: appConfig.apiUrl.getMerchantslists,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        Accept: " application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        if (error?.response?.status === 401) {
          fireReloginPopup();
          return;
        }
        reject(error);
      });
  });
};
const getpartnerOffers = (partner) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.getOffersByPartner}/${partner}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        handleError({ error, reject });
      });
  });
};
const updatetransactionsList = (obj) => {
  return new Promise((resolve, reject) => {
    var data = JSON.stringify({
      id: obj.id,
      localOffersId: obj.localOffersId,
      status: obj.status,
    });
    var config = {
      method: "post",
      url: appConfig.apiUrl.updateTransactions,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        Accept: " application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        if (error?.response?.status === 401) {
          fireReloginPopup();
          return;
        }
        reject(error);
      });
  });
};
export {
  updatetransactionsList,
  getpartnerOffers,
  getmerchantMappingList,
  getOfferStoreDataById,
  updateOfferStoresRecord,
  getTransactionsList,
  gettransactionDataById,
  getMerchantUser,
  getOfferDatas,
};
