import appConfig from "../config/app.config";
import axios from "axios";
import { getUserToken } from "../libs/user";
import {
  fireReloginPopup,
  getPureJSON,
  handleAPIError,
  handleApiSuccess,
  handleError,
  startLoader,
  stopLoader,
} from "../libs/utils";

const getUserProfile = (email) => {
  startLoader();
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.getUserProfile}/${email}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        handleError({ error, reject });
      });
  });
};

const getWallet = (email) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.getWallet}/${email}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        handleError({ error, reject });
      });
  });
};
const getWalletHistory = (email) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.getWalletHistory}/${email}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        handleError({ error, reject });
      });
  });
};
const getEventHistory = (email) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.getEventHistory}/${email}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        handleError({ error, reject });
      });
  });
};
const getEmailEventsHistory = (email) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.getEmailEventsHistory}/${email}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        handleError({ error, reject });
      });
  });
};
const getBankTransactionHistory = (email) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.getBankTransactionHistory}/${email}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        handleError({ error, reject });
      });
  });
};
const getOffersTrackingHistory = (id) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.getOffersTrackingHistory}/${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        handleError({ error, reject });
      });
  });
};
const getUserAccountDetails = (email) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.getUserAccountDetails}/${email}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        handleError({ error, reject });
      });
  });
};

const userReferralService = (email) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.referralInfo}/${email}/referral-info`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch(function (error) {
        handleError({ error, reject });
      });
  });
};

const accountSync = (userId) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const data = JSON.stringify({
      userId: userId,
    });

    const config = {
      method: "post",
      url: appConfig.apiUrl.accountSync,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        handleError({ error, reject });
      });
  });
};

const updateUserStatus = (email, status) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.changeUserStatus}/${email}/${status}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        handleError({ error, reject });
      });
  });
};

const getCMSUsers = () => {
  startLoader();
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.getcmsusers}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        handleError({ error, reject });
      });
  });
};

const changeUserEmail = (email, newEmail) => {
  startLoader();
  return new Promise((resolve, reject) => {
    const data = JSON.stringify({
      email: email,
      new_email: newEmail,
    });

    var config = {
      method: "post",
      url: appConfig.apiUrl.changeUserEmail,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        handleError({ error, reject });
      });
  });
};
const updateaddCmsUser = (obj) => {
  startLoader();
  const authToken = getUserToken();
  return new Promise((resolve, reject) => {
    var data = JSON.stringify({
      username: obj.username,
      email: obj.email,
      roles: obj.roles,
      is_active: obj.is_active,
    });

    var config = {
      method: "post",
      url: appConfig.apiUrl.addcmsusers,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        handleError({ error, reject });
      });
  });
};
const getCMSusersById = (id) => {
  startLoader();
  const authToken = getUserToken();

  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.getcmsUserid}/${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        handleError({ error, reject });
      });
  });
};
const editcmsUsers = (obj) => {
  startLoader();
  const authToken = getUserToken();
  return new Promise((resolve, reject) => {
    var data = JSON.stringify({
      id: obj.id,
      roles: obj.roles,
      is_active: obj.is_active,
    });

    var config = {
      method: "post",
      url: appConfig.apiUrl.editcmsUserid,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        handleError({ error, reject });
      });
  });
};
const deletecmsUsers = (obj) => {
  startLoader();
  const authToken = getUserToken();
  return new Promise((resolve, reject) => {
    var data = JSON.stringify({
      id: obj.id,
    });

    var config = {
      method: "post",
      url: appConfig.apiUrl.deletecmsUserid,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        handleError({ error, reject });
      });
  });
};

const deleteUserService = (email) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const config = {
      method: "delete",
      url: `${appConfig.apiUrl.deleteUser}/${email}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        handleError({ error, reject });
      });
  });
};

const send2faEmail = (id) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const data = JSON.stringify({
      id: id,
    });

    const config = {
      method: "post",
      url: `${appConfig.apiUrl.sendEmail}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        handleError({ error, reject });
      });
  });
};

const getTransactionDetails = (id) => {
  startLoader();

  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.transactionDetails}/${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        handleError({ error, reject });
      });
  });
};
const restoreUserService = (email) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const data = JSON.stringify({
      email: email,
    });

    var config = {
      method: "post",
      url: appConfig.apiUrl.restoreUser,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserToken()}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        handleError({ error, reject });
      });
  });
};

const getBankUserInfo = (identifier) => {
  startLoader();

  const isNumber = isNaN(Number(identifier)) ? false : true;

  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: appConfig.apiUrl.getBankUserInfo(
        isNumber ? `+1${identifier}` : identifier
      ),
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        handleError({ error, reject });
      });
  });
};

const getTxnDetails = (email, id) => {
  startLoader();

  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: appConfig.apiUrl.getTxnDetails(email, id),
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        handleError({ error, reject });
      });
  });
};

const getUserRemittanceTxn = (email, stoploader = true) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: appConfig.apiUrl.getUserRemittanceTxn(email),
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        if (stoploader) {
          stopLoader();
        }

        resolve(response.data);
      })
      .catch(function (error) {
        if (stoploader) {
          stopLoader();
        }
        handleError({ error, reject });
      });
  });
};

const getUserAchData = (customer_id) => {
  startLoader();
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.achData}/${customer_id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        if (error?.response?.status === 401) {
          fireReloginPopup();
          return;
        }
        handleAPIError(error.status_code, error.message);
        reject(getPureJSON(error.response || error));
      });
  });
};

const passwordReset = (email) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const data = JSON.stringify({
      email: email,
    });

    const config = {
      method: "post",
      url: appConfig.apiUrl.forgotPassword,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        handleError({ error, reject });
      });
  });
};

const updateRiskProfileById = (obj) => {
  const { customerId, riskScore, user_type } = obj;
  startLoader(1);
  return new Promise((resolve, reject) => {
    const data = {
      risk_profile: riskScore,
      user_type,
    };
    const config = {
      method: "post",
      url: `${appConfig.apiUrl.updateRiskProfile}/${customerId}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        handleApiSuccess(response.data.success, response.data.response);
        if (response.data.error) {
          handleAPIError(response.error.status);
        }
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        if (error?.response?.status === 401) {
          fireReloginPopup();
          return;
        }
        handleAPIError(error.status);
        reject(getPureJSON(error.response || error));
      });
  });
};

const updateUserPhone = (userDetails) => {
  const { new_mobile_number, token, customer_id } = userDetails;
  startLoader(1);
  return new Promise((resolve, reject) => {
    const data = {
      new_mobile_number,
      token,
    };
    const config = {
      method: "post",
      url: `${appConfig.apiUrl.changeUserPhone}/${customer_id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        handleApiSuccess(response.data.success, response.data.response);
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        if (error?.response?.status === 401) {
          fireReloginPopup();
          return;
        }
        handleAPIError(error.status);
        reject(getPureJSON(error.response || error));
      });
  });
};

const enableEmailOtp = (username, active) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const config = {
      method: "post",
      url: `${appConfig.apiUrl.enableEmailOtp}/${username}/${active}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };
    axios(config)
      .then(function (response) {
        handleApiSuccess(response.data.success, response.data.response);
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        if (error?.response?.status === 401) {
          fireReloginPopup();
          return;
        }
        handleAPIError(error.status);
        reject(getPureJSON(error.response || error));
      });
  });
};

const updateTxnRecoveryData = (id) => {
  startLoader();
  return new Promise((resolve, reject) => {
    const config = {
      method: "post",
      url: `${appConfig.apiUrl.paidByStripe}?transaction_ref_id=${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        handleError({ error, reject });
      });
  });
};

const cancelTxnService = (id) => {
  startLoader();
  return new Promise((resolve, reject) => {
    const config = {
      method: "post",
      url: `${appConfig.apiUrl.cancelTxn}/${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        handleError({ error, reject });
      });
  });
};
export {
  getUserProfile,
  getWallet,
  getWalletHistory,
  getEventHistory,
  getEmailEventsHistory,
  getBankTransactionHistory,
  getOffersTrackingHistory,
  getUserAccountDetails,
  accountSync,
  updateUserStatus,
  changeUserEmail,
  getCMSUsers,
  updateaddCmsUser,
  getCMSusersById,
  editcmsUsers,
  deletecmsUsers,
  deleteUserService,
  restoreUserService,
  send2faEmail,
  getBankUserInfo,
  getUserRemittanceTxn,
  getTxnDetails,
  passwordReset,
  userReferralService,
  updateRiskProfileById,
  getUserAchData,
  updateUserPhone,
  enableEmailOtp,
  getTransactionDetails,
  updateTxnRecoveryData,
  cancelTxnService,
};
