import FingerprintJS from "@fingerprintjs/fingerprintjs";
import moment from "moment";
import nProgress from "nprogress";
import Swal from "sweetalert2";
import { LOGIN_PATH } from "../constants";

const getRecaptcha = (action) => {
  return new Promise((resolve, reject) => {
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY, {
          action: action,
        })
        .then((token) => {
          resolve(token);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
};

const getBrowserFingerprint = () => {
  // Get the visitor identifier when you need it.
  return new Promise((resolve, reject) => {
    const fpPromise = FingerprintJS.load();
    fpPromise
      .then((fp) => fp.get())
      .then((result) => {
        resolve(result.visitorId);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getPureJSON = (data) => {
  return JSON.parse(JSON.stringify(data));
};

const filterList = (list = [], searchKey) => {
  return list.filter((entry) =>
    Object.values(entry).some((val) =>
      // typeof val === "string" &&
      String(val).toLowerCase().includes(searchKey.toLowerCase())
    )
  );
};

const searchExactList = (list = [], searchKey) => {
  if (!searchKey) {
    return list;
  }
  return list.filter((entry) =>
    Object.values(entry).some(
      (val) =>
        // typeof val === "string" &&
        String(val).toLowerCase() === searchKey.toLowerCase()
    )
  );
};

const filterExpiredOffers = (list = []) => {
  const expiredOffers = list.filter((item) => {
    const { ExpiryDate } = item;
    const momentDate = moment(ExpiryDate);
    return momentDate.isBefore(moment());
  });
  return expiredOffers;
};

const startLoader = (flag) => {
  nProgress.start();
  if (flag === 1) {
    document.querySelector("html").style.pointerEvents = "none";
  }
};

const stopLoader = (flag) => {
  nProgress.done();
  if (flag === 1) {
    document.querySelector("html").style.pointerEvents = "auto";
  }
};

const handleApiSuccess = (success, message) => {
  if (success) {
    Swal.fire({
      title: "Success",
      text: message,
      icon: "success",
      confirmButtonText: "Ok",
    }).then((result) => {});
  }
};
const handleAPIError = (statusCode, message) => {
  if (statusCode === 404) {
    Swal.fire({
      title: "Oops",
      text: "Data not found",
      icon: "warning",
      confirmButtonText: "Ok",
    }).then((result) => {});
  } else if (statusCode === 500) {
    Swal.fire({
      title: "Oops",
      text: message,
      icon: "warning",
      confirmButtonText: "Ok",
    }).then((result) => {});
  } else {
    Swal.fire({
      title: "Oops",
      text: "Something went wrong",
      icon: "warning",
      confirmButtonText: "Ok",
    }).then((result) => {});
  }
};

const fireReloginPopup = () => {
  localStorage.removeItem("cmsToken");
  localStorage.removeItem("userinfo");
  Swal.fire({
    title: "Relogin required",
    text: "Your session is expired, please login again!",
    icon: "warning",
    confirmButtonText: "Ok",
  }).then((result) => {
    if (result.isConfirmed) {
      window.location.href = LOGIN_PATH;
    }
  });
};

const handleError = ({ error, reject }) => {
  if (error?.response?.status === 401) {
    fireReloginPopup();
    return;
  }
  reject(getPureJSON(error.response || error));
};

const capitalizeFirstLetter = (string) => {
  try {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } catch (error) {
    return null;
  }
};

const getUserInfo = () => {
  return JSON.parse(localStorage.getItem("userinfo") || "{}");
};

const hasRole = (roles = []) => {
  return getUserInfo().roles;
  // try {
  //   let roleExist = false;
  //   roles.forEach((role) => {
  //     if (role && getUserInfo().roles.includes(role)) {
  //       roleExist = true;
  //     }
  //   });
  //   return roleExist ? true : false;
  // } catch (error) {
  //   console.error(error.message);
  //   return false;
  // }
};

const handleDuplicateValues = (input1, input2, value) => {
  let values = [...input2];
  input1.forEach((obj) => {
    const index = values.findIndex((data) => data[value] === obj);
    if (index > -1) {
      values.splice(index, 1);
    }
  });
  return values;
};

const inputFormat = (e) => {
  let { value } = e.target;
  let tempVal = "";
  const input = value.replace(/\D/g, "").substring(0, 10);
  const formattedVal = "+1" + input;
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);
  if (input.length > 6) {
    tempVal = `(${zip}) ${middle}-${last}`;
  } else if (input.length > 3) {
    tempVal = `(${zip}) ${middle}`;
  } else if (input.length > 0) {
    tempVal = `(${zip}`;
  }
  return tempVal;
};
export {
  getRecaptcha,
  getBrowserFingerprint,
  getPureJSON,
  filterList,
  startLoader,
  stopLoader,
  searchExactList,
  handleAPIError,
  capitalizeFirstLetter,
  filterExpiredOffers,
  getUserInfo,
  hasRole,
  handleDuplicateValues,
  handleApiSuccess,
  inputFormat,
  handleError,
  fireReloginPopup,
};
