import appConfig from "../config/app.config";
import axios from "axios";
import { getUserToken } from "../libs/user";
import {
  fireReloginPopup,
  getPureJSON,
  handleAPIError,
  handleError,
  startLoader,
  stopLoader,
} from "../libs/utils";

const getcategorylist = () => {
  startLoader();
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: appConfig.apiUrl.categorylist,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        if (error?.response?.status === 401) {
          fireReloginPopup();
          return;
        }
        reject(error);
      });
  });
};

const getcategorybyId = (id) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.categorylist}/${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        if (error?.response?.status === 401) {
          fireReloginPopup();
          return;
        }
        handleAPIError(error.status);
        reject(getPureJSON(error.response || error));
      });
  });
};

const updateCategory = (obj) => {
  startLoader(1);
  const authToken = getUserToken();
  return new Promise((resolve, reject) => {
    var data = JSON.stringify({
      id: obj.id,
      logo: obj.logo,
      name: obj.name,
      popularity: obj.popularity,
      active: obj.active,
      categoryType: obj.categoryType,
      showOnHome: obj.showOnHome,
      description: obj.description,
    });

    var config = {
      method: "post",
      url: appConfig.apiUrl.categorylist + "/" + "save",
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        handleError({ error, reject });
      });
  });
};
export { getcategorylist, getcategorybyId, updateCategory };
