import appConfig from "../config/app.config";
import axios from "axios";
import { getUserToken } from "../libs/user";
import {
  fireReloginPopup,
  getPureJSON,
  handleAPIError,
  startLoader,
  stopLoader,
} from "../libs/utils";

const riskConfigList = (source) => {
  startLoader();
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: `${appConfig.apiUrl.userRiskConfiguration}?source=${source}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data.risk_data);
      })
      .catch(function (error) {
        stopLoader();
        if (error?.response?.status === 401) {
          fireReloginPopup();
          return;
        }
        handleAPIError(error.status, error.message);
        reject(error);
      });
  });
};

const getRiskConfigDataById = (risk_profile, source) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.userRiskConfiguration}/${risk_profile}?source=${source}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        if (error?.response?.status === 401) {
          fireReloginPopup();
          return;
        }
        handleAPIError(error.status, error.message);
        reject(getPureJSON(error.response || error));
      });
  });
};

const addRiskConfig = (savedRiskConfigData, sourceParam) => {
  startLoader();
  return new Promise((resolve, reject) => {
    var config = {
      method: "post",
      url: `${appConfig.apiUrl.userRiskConfiguration}?source=${sourceParam}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: savedRiskConfigData,
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        if (error?.response?.status === 401) {
          fireReloginPopup();
          return;
        }
        handleAPIError(error.status, error.message);
        reject(getPureJSON(error.response || error));
      });
  });
};

const updateRiskConfig = (savedRiskConfigData, sourceParam) => {
  const { risk_profile } = savedRiskConfigData || {};
  startLoader();
  return new Promise((resolve, reject) => {
    var config = {
      method: "post",
      url: `${appConfig.apiUrl.updateRiskConfiguration}/${risk_profile}?source=${sourceParam}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: savedRiskConfigData,
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        if (error?.response?.status === 401) {
          fireReloginPopup();
          return;
        }
        handleAPIError(error.status, error.message);
        reject(getPureJSON(error.response || error));
      });
  });
};

export {
  riskConfigList,
  getRiskConfigDataById,
  updateRiskConfig,
  addRiskConfig,
};
