import appConfig from "../config/app.config";
import axios from "axios";
import { getUserToken } from "../libs/user";
import {
  fireReloginPopup,
  handleError,
  startLoader,
  stopLoader,
} from "../libs/utils";

const getPPOList = () => {
  startLoader();
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: appConfig.apiUrl.ppo,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        handleError({ error, reject });
      });
  });
};

const getPPOById = (id) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.ppo}/${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        handleError({ error, reject });
      });
  });
};

const editPPO = (id, obj) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const data = JSON.stringify(Object.assign({ id: id }, obj));

    var config = {
      method: "post",
      url: `${appConfig.apiUrl.ppo}/save`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        handleError({ error, reject });
      });
  });
};

const addPPO = (obj) => {
  return new Promise((resolve, reject) => {
    const data = JSON.stringify(obj);

    var config = {
      method: "post",
      url: `${appConfig.apiUrl.ppo}/save`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        handleError({ error, reject });
      });
  });
};

const fileUpload = (type, file, id) => {
  return new Promise((resolve, reject) => {
    const data = new FormData();
    data.append("type", type);
    data.append("file", file);
    data.append("id", id);
    const config = {
      method: "post",
      url: appConfig.apiUrl.ppoFileUpload,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        handleError({ error, reject });
      });
  });
};

const fileDownload = (type, id) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.ppoFileDownload}?type=${type}&id=${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        if (error?.response?.status === 401) {
          fireReloginPopup();
          return;
        }
        reject(error);
      });
  });
};

export { getPPOList, getPPOById, editPPO, addPPO, fileUpload, fileDownload };
