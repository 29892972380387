import appConfig from "../config/app.config";
import axios from "axios";
import { getUserToken } from "../libs/user";
import { fireReloginPopup, handleError } from "../libs/utils";

const getUserTransactionsList = (txnData) => {
  return new Promise((resolve, reject) => {
    const { month, page_number, page_size, year, account_id, customer_id } =
      txnData;
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.userTransactionHistory}?account_id=${account_id}&month=${month}&year=${year}&customer_id=${customer_id}&page_number=${page_number}&page_size=${page_size}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        Accept: " application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data.response);
      })
      .catch(function (error) {
        if (error?.response?.status === 401) {
          fireReloginPopup();
          return;
        }
        reject(error);
      });
  });
};

const getTransactionCount = (requestParams) => {
  return new Promise((resolve, reject) => {
    const { year, account_id } = requestParams;
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.userTransactionCount}?account_id=${account_id}&year=${year}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        resolve(response.data.response);
      })
      .catch(function (error) {
        if (error?.response?.status === 401) {
          fireReloginPopup();
          return;
        }
        reject(error);
      });
  });
};

export { getUserTransactionsList, getTransactionCount };
