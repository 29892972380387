import appConfig from "../config/app.config";
import axios from "axios";
import { getUserToken } from "../libs/user";
import {
  fireReloginPopup,
  getPureJSON,
  handleAPIError,
  handleError,
  startLoader,
  stopLoader,
} from "../libs/utils";

const getOffersStoresList = () => {
  startLoader();
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: appConfig.apiUrl.getstoresallList,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        handleError({ error, reject });
      });
  });
};

const getOfferStoreDataById = (id) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.getstoresallList}/${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        if (error?.response?.status === 401) {
          fireReloginPopup();
          return;
        }
        handleAPIError(error.status);
        reject(getPureJSON(error.response || error));
      });
  });
};

const updateOfferStoresRecord = (obj) => {
  startLoader(1);

  return new Promise((resolve, reject) => {
    var data = JSON.stringify({
      id: obj.id,
      businessStatus: obj.businessStatus,
      name: obj.name,
      formattedAddress: obj.formattedAddress,
      placeId: obj.placeId,
      stateName: obj.stateName,
      cityName: obj.cityName,
      rating: obj.rating,
      userRatingsTotal: obj.userRatingsTotal,
      types: obj.types,
      keywords: obj.keywords,
      geometry: obj.geometry,
    });

    var config = {
      method: "post",
      url: appConfig.apiUrl.updateofferstoresRecord,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        handleError({ error, reject });
      });
  });
};
export { getOffersStoresList, getOfferStoreDataById, updateOfferStoresRecord };
