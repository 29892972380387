import appConfig from "../config/app.config";
import axios from "axios";
import { getUserToken } from "../libs/user";
import { handleError, startLoader, stopLoader } from "../libs/utils";

const getOfferGroupsList = () => {
  startLoader();
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: appConfig.apiUrl.offerGroups,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        handleError({ error, reject });
      });
  });
};

const getOfferGroupsById = (id) => {
  startLoader();
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: `${appConfig.apiUrl.offerGroups}/${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        handleError({ error, reject });
      });
  });
};

const editOfferGroups = (id, obj) => {
  startLoader();
  return new Promise((resolve, reject) => {
    var data = JSON.stringify(Object.assign({ id: id }, obj));

    var config = {
      method: "post",
      url: `${appConfig.apiUrl.offerGroups}/save`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        handleError({ error, reject });
      });
  });
};

const addOfferGroups = (obj) => {
  startLoader();
  return new Promise((resolve, reject) => {
    var data = JSON.stringify(obj);

    var config = {
      method: "post",
      url: `${appConfig.apiUrl.offerGroups}/save`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        handleError({ error, reject });
      });
  });
};

export {
  getOfferGroupsList,
  getOfferGroupsById,
  editOfferGroups,
  addOfferGroups,
};
