import localforage from "localforage";
import jwt_decode from "jwt-decode";
import moment from "moment";

const getUserToken = () => {
  const token = localStorage.getItem("cmsToken");
  if (token) {
    return token;
  }
  return false;
};

const setUserToken = (token) => {
  if (token) {
    localStorage.setItem("cmsToken", token);
    return true;
  }
  return false;
};

const removeUserToken = (token) => {
  localStorage.removeItem("cmsToken");
  return true;
};

const setUser = (obj) => {
  return new Promise((resolve, reject) => {
    localforage.setItem("@user", obj, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
};

const getUser = () => {
  return new Promise((resolve, reject) => {
    localforage.getItem("@user", (err, data) => {
      if (err) {
        reject(err);
      } else if (data === null) {
        reject("user not logged in");
      } else {
        resolve(data);
      }
    });
  });
};

const removeUser = () => {
  return new Promise((resolve, reject) => {
    localforage.clear((error) => {
      if (error) {
        reject(error);
      } else {
        resolve("user removed");
      }
    });
  });
};

const decodeCMSUserToken = (token) => {
  const decoded = jwt_decode(token);
  const expiresAt = moment().add(decoded["exp"], "second");

  var userInfo = {
    user: decoded["sub"],
    token,
    expires: JSON.stringify(expiresAt.valueOf()),
    roles: decoded["role"],
  };
  localStorage.setItem("userinfo", JSON.stringify(userInfo));
};

export {
  setUser,
  getUser,
  getUserToken,
  setUserToken,
  removeUserToken,
  removeUser,
  decodeCMSUserToken,
};
