import appConfig from "../config/app.config";
import axios from "axios";
import { getUserToken } from "../libs/user";
import {
  fireReloginPopup,
  getPureJSON,
  handleAPIError,
  handleError,
  startLoader,
  stopLoader,
} from "../libs/utils";

const getPendingcashback = () => {
  startLoader();
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: appConfig.apiUrl.pendingCashback,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        handleError({ error, reject });
      });
  });
};
const getPendingBankLists = () => {
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: appConfig.apiUrl.getPendingBankList,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        handleError({ error, reject });
      });
  });
};

const updateCashbackRequestRecord = (obj) => {
  startLoader();
  return new Promise((resolve, reject) => {
    const data = JSON.stringify({
      status: obj.status,
      approval_comment: obj.approval_comment,
      remark: obj.remark,
      cashback_request_id: obj.cashback_request_id,
    });

    const config = {
      method: "post",
      url: appConfig.apiUrl.processpendingCashback,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        handleError({ error, reject });
      });
  });
};

const getCashbackReceipt = (cashbackRequestId) => {
  startLoader();
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: `${appConfig.apiUrl.getReceipt}/${cashbackRequestId}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        handleError({ error, reject });
      });
  });
};

const processBulkCash = (csvFile) => {
  startLoader();
  return new Promise((resolve, reject) => {
    const data = new FormData();
    data.append("file", csvFile);
    var config = {
      method: "post",
      url: `${appConfig.apiUrl.cashbackProcessing}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        if (error?.response?.status === 401) {
          fireReloginPopup();
          return;
        }
        handleAPIError(error.status);
        reject(getPureJSON(error.response || error));
      });
  });
};

export {
  getPendingcashback,
  updateCashbackRequestRecord,
  getPendingBankLists,
  getCashbackReceipt,
  processBulkCash,
};
