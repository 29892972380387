const apiBaseUrl = `https://api.timesclub.co/cms`;

const config = {
  timeoutDuration: 1440,
  apiUrl: {
    timesPrimeData: `${apiBaseUrl}/times-prime-data`,
    OffersList: `${apiBaseUrl}/offers/`,
    userLogin: `${apiBaseUrl}/login`,
    activeCoupons: `${apiBaseUrl}/active-coupons`,
    getPendingBankList: `${apiBaseUrl}/get-failed-linked-bank`,
    updateBankRecord: `${apiBaseUrl}/add-failed-bank`,
    getUserProfile: `${apiBaseUrl}/user`,
    getWallet: `${apiBaseUrl}/wallet`,
    getWalletHistory: `${apiBaseUrl}/wallet/history`,
    getEventHistory: `${apiBaseUrl}/user/event`,
    getEmailEventsHistory: `${apiBaseUrl}/user/email-event`,
    getBankTransactionHistory: `${apiBaseUrl}/user/bank-add-transaction`,
    getOffersTrackingHistory: `${apiBaseUrl}/transactions/offer-data`,
    getUserAccountDetails: `${apiBaseUrl}/plaid-banks/get-account-details`,
    accountSync: `${apiBaseUrl}/plaid-banks/accountSync`,
    changeUserEmail: `${apiBaseUrl}/user/change-email`,
    changeUserPhone: `${apiBaseUrl}/user/change-mobile-number`,
    changeUserStatus: `${apiBaseUrl}/user`,
    getActiveOffers: `${apiBaseUrl}/offers/active`,
    giveCashback: `${apiBaseUrl}/wallet/cashback`,
    bankAddRule: `${apiBaseUrl}/bank-add-rule-engine`,
    merchant: `${apiBaseUrl}/offers/merchantDetails`,
    cardSequence: `${apiBaseUrl}/card-sequence`,
    getFreeOffers: `${apiBaseUrl}/offers/active/true/`,
    geTtiers: `${apiBaseUrl}/tiers/`,
    getallactivecategories: `${apiBaseUrl}/category/active`,
    getOfferDataById: `${apiBaseUrl}/offers/offer`,
    listappConfig: `${apiBaseUrl}/app-config`,
    getalloffergroups: `${apiBaseUrl}/offer-groups`,
    updateOffersRecord: `${apiBaseUrl}/offers/save`,
    offersupload: `${apiBaseUrl}/offers/upload`,
    offersimagedownload: `${apiBaseUrl}/offers/download`,
    getApplicationConfigdataById: `${apiBaseUrl}/app-config`,
    updateappconfigRecord: `${apiBaseUrl}/app-config/save`,
    getstoresallList: `${apiBaseUrl}/offers/stores`,
    updateofferstoresRecord: `${apiBaseUrl}/offers/stores/save`,
    offerGroups: `${apiBaseUrl}/offer-groups`,
    OffersStoresLinked: `${apiBaseUrl}/offer-store/active`,
    getforoffer: `${apiBaseUrl}/offer-store/offer/`,
    offergroupid: `${apiBaseUrl}/offer-groups/offer/`,
    getmerchantMappingAuditList: `${apiBaseUrl}/plaid-transactions/get-txns`,
    getcmsusers: `${apiBaseUrl}/cms-users`,
    addcmsusers: `${apiBaseUrl}/cms-users/save`,
    getcmsUserid: `${apiBaseUrl}/cms-users/get`,
    editcmsUserid: `${apiBaseUrl}/cms-users/edit`,
    deletecmsUserid: `${apiBaseUrl}/cms-users/delete`,
    getTxnsStoreslists: `${apiBaseUrl}/offers/stores`,
    updatetxns: `${apiBaseUrl}/plaid-transactions/update-txns`,
    getMerchantslists: `${apiBaseUrl}/transactions`,
    getMerchantsusers: `${apiBaseUrl}/user`,
    getofferData: `${apiBaseUrl}/transactions/offer-data`,
    getOffersByPartner: `${apiBaseUrl}/offers/partner`,
    updateTransactions: `${apiBaseUrl}/transactions/update`,
    getfaqList: `${apiBaseUrl}/faq`,
    listOfferStores: `${apiBaseUrl}/offer-store`,
    ppo: `${apiBaseUrl}/partner-promotional-offers`,
    deleteUser: `${apiBaseUrl}/user/delete-account`,
    restoreUser: `${apiBaseUrl}/user/restore`,
    pendingCashback: `${apiBaseUrl}/cashback/all`,
    processpendingCashback: `${apiBaseUrl}/cashback/process`,
    categorylist: `${apiBaseUrl}/category`,
    howitworklist: `${apiBaseUrl}/how-it-works`,
    tierslist: `${apiBaseUrl}/tiers`,
    plaidbankslist: `${apiBaseUrl}/plaid-banks`,
    getReceipt: `${apiBaseUrl}/cashback/receipt`,
    bonusReferral: `${apiBaseUrl}/referral-campaigns`,
    tierBenefit: `${apiBaseUrl}/benefit`,
    bank: `${apiBaseUrl}/bank`,
    processTransaction: `${apiBaseUrl}/plaid-transactions/processTransactions`,
    forgotPassword: `${apiBaseUrl}/password/forget`,
    resetPassword: `${apiBaseUrl}/password/reset`,
    staticJSON: `${apiBaseUrl}/app-config/json-file`,
    topCardStaticJSON: `${apiBaseUrl}/app-config/top-cards`,
    rules: `${apiBaseUrl}/csm-rule`,
    updateRules: `${apiBaseUrl}/csm-rule/save`,
    eligibleCsmRules: `${apiBaseUrl}/csm-rule/eligible-csm-rules`,
    factorAuthentication: `${apiBaseUrl}/auth`,
    sendEmail: `${apiBaseUrl}/cms-users/add-2fa-to-cms-user`,
    ppoFileUpload: `${apiBaseUrl}/partner-promotional-offers/upload`,
    ppoFileDownload: `${apiBaseUrl}/partner-promotional-offers/download`,
    affiliatePartner: `${apiBaseUrl}/affiliate-partner`,
    neobanklistConfig: `${apiBaseUrl}/neobank/app-config`,
    neobankUpdateConfigRecord: `${apiBaseUrl}/neobank/app-config/save`,
    getNeobankAppConfigdataById: `${apiBaseUrl}/neobank/app-config`,
    getBankUserInfo: (identifier) => `${apiBaseUrl}/neobank/user/${identifier}`,
    getUserRemittanceTxn: (email) =>
      `${apiBaseUrl}/neobank/user/transactions/${email}`,
    returnEligibleTxn: `${apiBaseUrl}/neobank/return-eligible-transactions`,
    returnTxn: `${apiBaseUrl}/neobank/process-return`,
    getTxnDetails: (email, id) =>
      `${apiBaseUrl}/neobank/user/${email}/transactions-details/${id}`,
    getUploadedImages: `${apiBaseUrl}/offers/get-uploaded-images`,
    referralInfo: `${apiBaseUrl}/user`,
    fxRateCampaignList: `${apiBaseUrl}/neobank/fx-rate-campaigns`,
    fxRateCampaign: `${apiBaseUrl}/neobank/fx-rate-campaign`,
    fxRateCompare: `${apiBaseUrl}/neobank/fetch-provider-remittance-rates`,
    getBlockedUsersList: `${apiBaseUrl}/neobank/blocked-remittance-users`,
    unblockRemittanceUser: `${apiBaseUrl}/neobank/unblock-remittance-user`,
    updateRiskProfile: `${apiBaseUrl}/neobank/update-user`,
    userRiskConfiguration: `${apiBaseUrl}/neobank/user-risk-configurations`,
    updateRiskConfiguration: `${apiBaseUrl}/neobank/update-user-risk-configurations`,
    achData: `${apiBaseUrl}/neobank/ach-data`,
    stateCode: `${apiBaseUrl}/offers/state-codes`,
    userTransactionHistory: `${apiBaseUrl}/plaid-transactions/fetch/user-transaction-history`,
    cashbackProcessing: `${apiBaseUrl}/wallet/process-bulk-cashback`,
    enableEmailOtp: `${apiBaseUrl}/user/update-email-otp-status`,
    userTransactionCount: `${apiBaseUrl}/plaid-transactions/fetch/user-transaction-count`,
    activityList: `${apiBaseUrl}/tp/activitiesList`,
    activityById: `${apiBaseUrl}/tp/viewActivity`,
    saveActivity: `${apiBaseUrl}/tp/saveActivity`,
    editActivity: `${apiBaseUrl}/tp/editActivity`,
    getActivitiesByCategory: `${apiBaseUrl}/tp/getActivitiesByCategory`,
    getActivitiesByChannel: `${apiBaseUrl}/tp/getActivitiesByChannel`,
    transactionDetails: `${apiBaseUrl}/neobank/transaction-details`,
    paidByStripe: `${apiBaseUrl}/neobank/update-transaction-recovery-data`,
    cancelTxn: `${apiBaseUrl}/neobank/cancel-transaction`,
    deleteRemittanceUser: `${apiBaseUrl}/user/hash-neobank-data`,
  },

  roles: {
    admin: "ROLE_ADMIN",
    neobank_admin: "ROLE_NEOBANK_SUPPORT",
  },
};

export default config;
