import appConfig from "../config/app.config";
import axios from "axios";
import { getUserToken } from "../libs/user";
import { handleError, startLoader, stopLoader } from "../libs/utils";

const getStaticJSON = () => {
  startLoader();
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: appConfig.apiUrl.staticJSON,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        handleError({ error, reject });
      });
  });
};

const setStaticJSON = (obj) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const data = JSON.stringify(obj);

    const config = {
      method: "post",
      url: `${appConfig.apiUrl.staticJSON}/save`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        handleError({ error, reject });
      });
  });
};

const getTCStaticJSON = () => {
  startLoader();
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: appConfig.apiUrl.topCardStaticJSON,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        handleError({ error, reject });
      });
  });
};

const setTCStaticJSON = (obj) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const data = JSON.stringify(obj);

    const config = {
      method: "post",
      url: `${appConfig.apiUrl.topCardStaticJSON}/save`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        handleError({ error, reject });
      });
  });
};

export { getStaticJSON, setStaticJSON, getTCStaticJSON, setTCStaticJSON };
