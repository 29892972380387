import appConfig from "../config/app.config";
import axios from "axios";
import { getUserToken } from "../libs/user";
import {
  fireReloginPopup,
  getPureJSON,
  handleAPIError,
  handleError,
  startLoader,
  stopLoader,
} from "../libs/utils";

const neobankConfigList = () => {
  startLoader();
  return new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: appConfig.apiUrl.neobanklistConfig,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data.data);
      })
      .catch(function (error) {
        stopLoader();
        if (error?.response?.status === 401) {
          fireReloginPopup();
          return;
        }
        reject(error);
      });
  });
};

const getNeobankConfigDataById = (id) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.getNeobankAppConfigdataById}/${id}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        if (error?.response?.status === 401) {
          fireReloginPopup();
          return;
        }
        handleAPIError(error.status);
        reject(getPureJSON(error.response || error));
      });
  });
};

const neobankUpdateAppConfig = (savedConfigData) => {
  startLoader();
  return new Promise((resolve, reject) => {
    var data = JSON.stringify({
      id: savedConfigData.id,
      key_name: savedConfigData.key_name,
      value: savedConfigData.value,
    });

    var config = {
      method: "post",
      url: appConfig.apiUrl.neobankUpdateConfigRecord,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        handleError({ error, reject });
      });
  });
};

export { neobankConfigList, getNeobankConfigDataById, neobankUpdateAppConfig };
